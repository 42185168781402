* {
  box-sizing: border-box;
  font-family: "mrs-eaves";
  scroll-behavior: smooth;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
}

html, body {
  height: 100%;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  line-height: 1.5;
  padding: 0;
  margin: 0;
}

.background {
  background-image: url("../public/venue.jpg");
  background-size: cover;
  background-position: center bottom;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: -1;
  animation-name: zoom;
  animation-duration: 20s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}
@keyframes zoom {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.5);
  }
}

.cursive {
  font-family: regina, cursive;
  font-weight: 400;
  font-style: normal;
}

.mrs-eaves-petite {
  font-family: mrs-eaves-roman-all-petite-c, serif;
  font-weight: 400;
  font-style: normal;
}

.mrs-eaves-roman {
  font-family: "mrs-eaves", serif;
  font-weight: 400;
  font-style: normal;
}

.sr-only {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

.fade {
  background-color: rgba(255, 255, 255, 0.9);
}


.container {
  height: 100%;
  max-width: 40em;
  padding: 1em;
  margin: 0 auto;
}

.center-contents-vertically {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.empty-page {
  min-height: 120vh;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0) 30%);

}

@media (prefers-color-scheme: dark) {
  body {
    background: #fff;
    color: #000;
  }
  .fade {
    background-color: rgba(255, 255, 255, 0.9);
  }
  .empty-page {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0) 30%);
  }
  
}

p {
  margin: 1em auto;
}

h1, h2, h3, h4, h5, h6 {
  font-family: mrs-eaves-roman-small-caps, serif;
  font-weight: 400;
  font-size: 1.5em;
}

header {
  min-height: 98vh;
}
header p {
  font-family: mrs-eaves-roman-all-petite-c, serif;
  font-weight: 400;
  font-style: normal;
}

header p.name {
  font-family: regina, cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 2em;
  margin: 0.5em auto;
}
@media screen and (min-width: 50em) {
  header p.name {
    font-size: 3em;
  }
}

.event {
  min-height: 70vh;
  margin-bottom: 10em;
}
.event img {
  max-width: 100%;
  margin: 0 auto;
}

.not-found {
  height: 100%;
}

.rsvp {
  font-family: mrs-eaves-roman-small-caps, serif;
  letter-spacing: 0.1em;
  display: inline-block;
  padding: 0.5em 1em;
  margin-top: 1em;
  color: #fff;
  background: #0E0015;
  border-radius: 5px;
  text-decoration: none;
}